/*
Copyright 2019 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

.spectrum-CircleLoader--indeterminate-fill-submask-2 {
  animation: spectrum-fill-mask-2 1s infinite linear;
}
@keyframes spectrum-fill-mask-1 {
  0% {
    transform: rotate(90deg);
  }

  1.69% {
    transform: rotate(72.3deg);
  }

  3.39% {
    transform: rotate(55.5deg);
  }

  5.08% {
    transform: rotate(40.3deg);
  }

  6.78% {
    transform: rotate(25deg);
  }

  8.47% {
    transform: rotate(10.6deg);
  }

  10.17% {
    transform: rotate(0deg);
  }

  11.86% {
    transform: rotate(0deg);
  }

  13.56% {
    transform: rotate(0deg);
  }

  15.25% {
    transform: rotate(0deg);
  }

  16.95% {
    transform: rotate(0deg);
  }

  18.64% {
    transform: rotate(0deg);
  }

  20.34% {
    transform: rotate(0deg);
  }

  22.03% {
    transform: rotate(0deg);
  }

  23.73% {
    transform: rotate(0deg);
  }

  25.42% {
    transform: rotate(0deg);
  }

  27.12% {
    transform: rotate(0deg);
  }

  28.81% {
    transform: rotate(0deg);
  }

  30.51% {
    transform: rotate(0deg);
  }

  32.2% {
    transform: rotate(0deg);
  }

  33.9% {
    transform: rotate(0deg);
  }

  35.59% {
    transform: rotate(0deg);
  }

  37.29% {
    transform: rotate(0deg);
  }

  38.98% {
    transform: rotate(0deg);
  }

  40.68% {
    transform: rotate(0deg);
  }

  42.37% {
    transform: rotate(5.3deg);
  }

  44.07% {
    transform: rotate(13.4deg);
  }

  45.76% {
    transform: rotate(20.6deg);
  }

  47.46% {
    transform: rotate(29deg);
  }

  49.15% {
    transform: rotate(36.5deg);
  }

  50.85% {
    transform: rotate(42.6deg);
  }

  52.54% {
    transform: rotate(48.8deg);
  }

  54.24% {
    transform: rotate(54.2deg);
  }

  55.93% {
    transform: rotate(59.4deg);
  }

  57.63% {
    transform: rotate(63.2deg);
  }

  59.32% {
    transform: rotate(67.2deg);
  }

  61.02% {
    transform: rotate(70.8deg);
  }

  62.71% {
    transform: rotate(73.8deg);
  }

  64.41% {
    transform: rotate(76.2deg);
  }

  66.1% {
    transform: rotate(78.7deg);
  }

  67.8% {
    transform: rotate(80.6deg);
  }

  69.49% {
    transform: rotate(82.6deg);
  }

  71.19% {
    transform: rotate(83.7deg);
  }

  72.88% {
    transform: rotate(85deg);
  }

  74.58% {
    transform: rotate(86.3deg);
  }

  76.27% {
    transform: rotate(87deg);
  }

  77.97% {
    transform: rotate(87.7deg);
  }

  79.66% {
    transform: rotate(88.3deg);
  }

  81.36% {
    transform: rotate(88.6deg);
  }

  83.05% {
    transform: rotate(89.2deg);
  }

  84.75% {
    transform: rotate(89.2deg);
  }

  86.44% {
    transform: rotate(89.5deg);
  }

  88.14% {
    transform: rotate(89.9deg);
  }

  89.83% {
    transform: rotate(89.7deg);
  }

  91.53% {
    transform: rotate(90.1deg);
  }

  93.22% {
    transform: rotate(90.2deg);
  }

  94.92% {
    transform: rotate(90.1deg);
  }

  96.61% {
    transform: rotate(90deg);
  }

  98.31% {
    transform: rotate(89.8deg);
  }

  100% {
    transform: rotate(90deg);
  }
}
@keyframes spectrum-fill-mask-2 {
  0% {
    transform: rotate(180deg);
  }

  1.69% {
    transform: rotate(180deg);
  }

  3.39% {
    transform: rotate(180deg);
  }

  5.08% {
    transform: rotate(180deg);
  }

  6.78% {
    transform: rotate(180deg);
  }

  8.47% {
    transform: rotate(180deg);
  }

  10.17% {
    transform: rotate(179.2deg);
  }

  11.86% {
    transform: rotate(164deg);
  }

  13.56% {
    transform: rotate(151.8deg);
  }

  15.25% {
    transform: rotate(140.8deg);
  }

  16.95% {
    transform: rotate(130.3deg);
  }

  18.64% {
    transform: rotate(120.4deg);
  }

  20.34% {
    transform: rotate(110.8deg);
  }

  22.03% {
    transform: rotate(101.6deg);
  }

  23.73% {
    transform: rotate(93.5deg);
  }

  25.42% {
    transform: rotate(85.4deg);
  }

  27.12% {
    transform: rotate(78.1deg);
  }

  28.81% {
    transform: rotate(71.2deg);
  }

  30.51% {
    transform: rotate(89.1deg);
  }

  32.2% {
    transform: rotate(105.5deg);
  }

  33.9% {
    transform: rotate(121.3deg);
  }

  35.59% {
    transform: rotate(135.5deg);
  }

  37.29% {
    transform: rotate(148.4deg);
  }

  38.98% {
    transform: rotate(161deg);
  }

  40.68% {
    transform: rotate(173.5deg);
  }

  42.37% {
    transform: rotate(180deg);
  }

  44.07% {
    transform: rotate(180deg);
  }

  45.76% {
    transform: rotate(180deg);
  }

  47.46% {
    transform: rotate(180deg);
  }

  49.15% {
    transform: rotate(180deg);
  }

  50.85% {
    transform: rotate(180deg);
  }

  52.54% {
    transform: rotate(180deg);
  }

  54.24% {
    transform: rotate(180deg);
  }

  55.93% {
    transform: rotate(180deg);
  }

  57.63% {
    transform: rotate(180deg);
  }

  59.32% {
    transform: rotate(180deg);
  }

  61.02% {
    transform: rotate(180deg);
  }

  62.71% {
    transform: rotate(180deg);
  }

  64.41% {
    transform: rotate(180deg);
  }

  66.1% {
    transform: rotate(180deg);
  }

  67.8% {
    transform: rotate(180deg);
  }

  69.49% {
    transform: rotate(180deg);
  }

  71.19% {
    transform: rotate(180deg);
  }

  72.88% {
    transform: rotate(180deg);
  }

  74.58% {
    transform: rotate(180deg);
  }

  76.27% {
    transform: rotate(180deg);
  }

  77.97% {
    transform: rotate(180deg);
  }

  79.66% {
    transform: rotate(180deg);
  }

  81.36% {
    transform: rotate(180deg);
  }

  83.05% {
    transform: rotate(180deg);
  }

  84.75% {
    transform: rotate(180deg);
  }

  86.44% {
    transform: rotate(180deg);
  }

  88.14% {
    transform: rotate(180deg);
  }

  89.83% {
    transform: rotate(180deg);
  }

  91.53% {
    transform: rotate(180deg);
  }

  93.22% {
    transform: rotate(180deg);
  }

  94.92% {
    transform: rotate(180deg);
  }

  96.61% {
    transform: rotate(180deg);
  }

  98.31% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(180deg);
  }
}
@keyframes spectrum-fills-rotate {
  0% {transform: rotate(-90deg)}
  100% {transform: rotate(270deg)}
}
